import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/orders/:orderHash',
    name: 'orders.show',
    component: () => import('@/views/OrderView.vue'),
    props: true,
  },
  {
    path: '/orders/:orderHash/status/:status/:reason?',
    name: 'orders.status',
    component: () => import('@/views/OrderStatusView.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/HomeView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
